const header = document.getElementById('navigation-header');
const mobileHeader = document.getElementById('mobile-header');
const mobileHeaderClose = document.getElementById(
  'header-button-mobile-close-btn'
);
const mobileHeaderOpen = document.getElementById(
  'header-button-mobile-open-btn'
);
const mobileMenuContent = document.getElementById('mobile-menu-content');

const mobileMenuBack = document.getElementById('header-button-mobile-back');
const mobileMenuHeader = document.getElementsByClassName('mobile-menu-header');

const mobileMenuCategoryContent = document.querySelectorAll(
  '.mobile-menu-category-content'
);

const resetMobileMenuCategoryContent = () => {
  mobileMenuCategoryContent.forEach((sidebarCategory) => {
    sidebarCategory.style.zIndex = '0';
  });
};

const load_mobile_search = async () => {
  const ReactDOM = await import('react-dom/client');
  const mobileRoot = ReactDOM.createRoot(
    document.getElementById('mobile-search-node')
  );

  const { default: algolia } = await import('../algolia');
  mobileRoot.render(algolia);
};

// Load mobile search
mobileHeaderOpen.addEventListener(
  'click',
  () => {
    load_mobile_search();
  },
  { once: true }
);

// Open Mobile Header
mobileHeaderOpen.addEventListener('click', () => {
  document.body.style.overflow = 'hidden';
  header.style.zIndex = 999999;
  mobileHeader.classList.add('open');
});

// Close Mobile Header
mobileHeaderClose.addEventListener('click', () => {
  mobileMenuContent.style.cssText = `
    transition: all 0.4s;
    transform: translateX(0);
  `;

  header.style.zIndex = 999;
  document.body.style.overflow = 'scroll';
  mobileHeader.classList.remove('open');

  setTimeout(() => {
    resetMobileMenuCategoryContent();
  }, 400);
});

// Menu Category sub-menu
for (let i = 0; i < mobileMenuHeader.length; i++) {
  mobileMenuHeader[i].addEventListener('click', () => {
    document.getElementById(
      mobileMenuHeader[i].dataset.sidebarCategory
    ).style.zIndex = '100';

    document.getElementById(
      mobileMenuHeader[i].dataset.sidebarCategory
    ).firstElementChild.scrollTop = '0';

    mobileMenuContent.style.cssText = `
        transition: all 0.4s;
        transform: translateX(-100%);
      `;
  });
}

// Menu Category Back-to-Main-Menu
mobileMenuBack.addEventListener('click', () => {
  mobileMenuContent.style.cssText = `
    transition: all 0.4s;
    transform: translateX(0);
  `;
  setTimeout(() => {
    resetMobileMenuCategoryContent();
  }, 400);
});
