const header = document.querySelector('header')

document.addEventListener("scroll", (e) => {
  let scroll = window.scrollY;
  if (scroll < 70) {
    header.classList.remove('header-scrolled')
  } else if (scroll > 100) {
    header.classList.add('header-scrolled')
  }
});
