const desktop_searchbar = document.getElementById("desktop-searchbar")
const desktop_searchbar_open = document.getElementById('desktop-searchbar-open')
const desktop_searchbar_close = document.getElementById('desktop-searchbar-close')
const desktop_search_node = document.getElementById('desktop-search-node')

let search_root

const open_desktop_searchbar = async () => {
  const ReactDOM = await import('react-dom/client')
  search_root = ReactDOM.createRoot(desktop_search_node)

  const {default: algolia} = await import('../algolia')
  search_root.render(algolia)

  desktop_searchbar.classList.add("expanded")
}

const close_desktop_searchbar = () => {
  search_root.unmount()
  desktop_searchbar.classList.remove("expanded")
}

desktop_searchbar_open.addEventListener('click', open_desktop_searchbar)
desktop_searchbar_close.addEventListener('click', close_desktop_searchbar)