const menuSelector = document.querySelectorAll('.menu-list > li.with-dropdown');

Array.from(menuSelector).forEach(function(element) {
  element.addEventListener('mouseover', (e) => {
    document.body.classList.add('dropdown');
  });
  element.addEventListener('mouseout', (e) => {
    document.body.classList.remove('dropdown');
  });
});
